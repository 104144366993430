import * as React from 'react'
import { CardInfo } from '@layout/types'
import styles from './ChooseUs.module.css'

type Props = Readonly<{ card: CardInfo }>

export const ChooseUsSmallCard: React.FC<Props> = ({ card }) => {
  return (
    <div className={styles.smallCard__card}>
      <div className={styles.smallCard__image}>
      <img src={card.image} alt={card.title} loading="lazy" className={styles.smallCard__icon} />
      </div>
      <div className={styles.smallCard__info}>
        <h3 className={styles.smallCard__title}>{card.title}</h3>
        <span className={styles.smallCard__text}>{card.text}</span>
      </div>
    </div>
  )
}
