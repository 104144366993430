import * as React from 'react'
import { useTranslation } from 'react-i18next'
import img1 from '@assets/images/badge_quality_vehicles.png'
import img2 from '@assets/images/badge_customer_feedback.png'
import img3 from '@assets/images/badge_fair_price.png'
import { CardInfo } from '@layout/types'
import { FlexContainer } from '@layout/FlexContainer'
import { ChooseUsSmallCard } from './ChooseUsSmallCard'
import styles from './ChooseUs.module.css'

export const ChooseUs: React.FC = () => {
  const { t } = useTranslation()

  const cardItems: ReadonlyArray<CardInfo> = React.useMemo(
    () => [
      {
        id: 1,
        image: img2,
        title: t('pages.main.sections.guarantee.text2'),
        text: t('pages.main.sections.guarantee.subtext2')
      },
      {
        id: 2,
        image: img1,
        title: t('pages.main.sections.guarantee.text1'),
        text: t('pages.main.sections.guarantee.subtext1')
      },
      {
        id: 3,
        image: img3,
        title: t('pages.main.sections.guarantee.text3'),
        text: t('pages.main.sections.guarantee.subtext3')
      }
    ],
    [t]
  )

  return (
    <FlexContainer centeredInBlock vertical className={styles.cardContainer}>
      <div className={styles.chooseUs__title}>{t('pages.main.sections.guarantee.title')}</div>
      <div  className={styles.chooseUs__grid}>
      {cardItems.map((card) => (
        <ChooseUsSmallCard key={`guarantee-card-${card.id}`} card={card}  />
      ))}
      </div>
    </FlexContainer>
  )
}
