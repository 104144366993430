import * as React from 'react'
import clsx from 'clsx'
import SvgGoogleRating from './GoogleRating2.svg'
import Star from '@assets/icons/star.svg'
import styles from './GoogleRating.module.css'
import { useTranslation } from 'react-i18next'

export type GoogleRatingProps = {
  value: number
  reviews?: number
  className?: string
  shape?: 'square' | 'round'
}

export const GoogleRating: React.FC<GoogleRatingProps> = ({ value, className, shape = 'square', reviews = 0 }) => {
  const { t } = useTranslation()

  return (
    <>
      {shape === 'square' ? (
        <div className={clsx(styles.googleRatingContainer, className)}>
          <img src={SvgGoogleRating} alt={`Google rating: ${value}`} className={styles.googleRatingContainer__image} />
          <div className={styles.googleRatingValue}>{value}</div>
        </div>
      ) : (
        <>
          <div className={clsx(styles.roundBadge, className)}>
            <div className={styles.roundBadge__content}>
              <div className={styles.roundBadge__title}>{t('translations:controls.google-rating.google')}</div>
              <div className={styles.roundBadge__rating}>{value}</div>
              <div className={styles.roundBadge__source}>
                <div className={styles.roundBadge__reviews}>{reviews}</div>
                <div className={styles.roundBadge__text}>{t('translations:controls.google-rating.reviews')}</div>
              </div>
              <img src={Star} alt="Star" className={styles.roundBadge__star} />
            </div>
          </div>
        </>
      )}
    </>
  )
}
