import * as React from 'react'
import img1 from '@assets/images/client_img1.png'
import img3 from '@assets/images/client_img3.png'
import img5 from '@assets/images/client_img5.png'
import Logo from '@assets/icons/c_round_small.svg'
import styles from './OurClients.module.css'

export const OurClients: React.FC = () => {
  return (
    <div className={styles.imageContainer}>
      {[img1, img3, img5].map((img, i) => (
        <img key={i} src={img} alt="client image" loading="lazy" />
      ))}
      <img src={Logo} alt="logo" className={styles.logo} />
    </div>
  )
}
