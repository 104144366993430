import * as React from 'react'
import { keepPreviousData, useQuery, UseQueryResult } from '@tanstack/react-query'
import { VehicleMake, VehicleModel } from '@api/endpoints'
import { getVehicleCountsByMakeAndModel, VehicleCount } from '@api/endpoints/vehicles'
import { useDefaultQueryErrorHandlerPublic } from '@hooks/ReactQuery'
import { queryKeyGetVehicleCountsByMakeAndModel } from './queryKeys'

export const useQueryGetVehicleCountsByMakeAndModel = (
  make?: VehicleMake,
  model?: VehicleModel
): UseQueryResult<VehicleCount> => {
  const errorHandler = useDefaultQueryErrorHandlerPublic()

  const query = useQuery({
    queryKey: queryKeyGetVehicleCountsByMakeAndModel(make, model),
    queryFn: () => getVehicleCountsByMakeAndModel(make, model),
    placeholderData: keepPreviousData
  })

  React.useEffect(() => {
    if (query.error) {
      errorHandler(query.error)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query.error])

  return query
}
