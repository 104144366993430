import * as React from 'react'
import { useTranslation } from 'react-i18next'
import SvgGoogleLogo from '@assets/icons/G_google.svg'
import SvgFBLogo from '@assets/icons/fbColored.svg'
import img1 from '@assets/images/client_img1.png'
import img2 from '@assets/images/client_img2.png'
import img3 from '@assets/images/client_img3.png'
import img4 from '@assets/images/client_img4.png'
import img5 from '@assets/images/client_img5.png'
import { useTheme } from '@hooks/useTheme'
import { useMediaQuery } from '@mui/material'
import { IconTextLink } from '@controls/buttons'
import styles from './Testimonials.module.css'
import { FlexContainer } from '@layout/FlexContainer'
import { fbLink, googleLink } from '@constants/links'
import { PageContent } from '@layout/Page'

export const Testimonials: React.FC = () => {
  const { t } = useTranslation()

  const { currentTheme } = useTheme()
  const isDownMd = useMediaQuery(currentTheme.breakpoints.down('md'))

  return (
    <PageContent className={styles.testimonialsContainer}>
      <FlexContainer fixedDirection className={styles.linksContainer}>
        <IconTextLink
          className={styles.link}
          href={googleLink}
          text={isDownMd ? t('pages.main.sections.readFeedbacks') : t('pages.main.sections.google')}
          target="_blank"
        >
          <FlexContainer alignItemsCenter justifyContentCenter fitWidth className={styles.logoContainer}>
            <img src={SvgGoogleLogo} alt="google" />
          </FlexContainer>
        </IconTextLink>
        <IconTextLink
          className={styles.link}
          href={fbLink}
          text={isDownMd ? t('pages.main.sections.checkNews') : t('pages.main.sections.facebook')}
          target="_blank"
        >
          <FlexContainer justifyContentCenter alignItemsCenter fitWidth className={styles.logoContainer}>
            <img src={SvgFBLogo} alt="Facebook" />
          </FlexContainer>
        </IconTextLink>
      </FlexContainer>
      <FlexContainer fixedDirection justifyContentCenter className={styles.imageContainer}>
        {[img1, img2, img3, img4, img5].map((img, i) => (
          <img key={i} src={img} alt="client image" loading="lazy" />
        ))}
      </FlexContainer>
    </PageContent>
  )
}
