import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { VehicleMake } from '@api/endpoints'
import { Button } from '@mui/material'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import formStyles from '@assets/css/forms.module.css'
import SvgLogo from '@assets/icons/dealerName.svg'
import ImgMainBackground from '@assets/images/backgroundMain.webp'
import { useLanguage } from '@hooks/useLanguage'
import { useQueryGetVehicleCountsByMakeAndModel } from '@hooks/ReactQuery/vehicles/useQueryGetVehicleCountsByMakeAndModel'
import { AllValueSchema } from '@controls/types'
import { renderMenuItems } from '@controls/utils'
import { FormTextField, useZodForm } from '@controls/Form'
import { useVehicleMakeMenuOptions, useVehicleModelMenuOptionsByMake } from '@controls/Menu'
import { GoogleRating } from '@controls/GoogleRating'
import { PageSection } from '@layout/PageSection'
import { FlexContainer } from '@layout/FlexContainer'
import { CatalogFiltrationForm, CatalogFiltrationFormSchema } from '@forms/Catalog/types'
import { useCatalogParamsStore, initialCatalogFiltrationForm, buildQueryParamFromFilterValue } from '@forms/Catalog'
import styles from './TopMain.module.css'

export const TopMain: React.FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { routes } = useLanguage()

  const setCatalogFiltrationForm = useCatalogParamsStore((s) => s.setCatalogFiltrationForm)

  const [make, setMake] = React.useState<VehicleMake | undefined>(undefined)

  const form = useZodForm({
    schema: CatalogFiltrationFormSchema,
    defaultValues: initialCatalogFiltrationForm
  })

  const navigateToCatalogHandler = (data: CatalogFiltrationForm): void => {
    setCatalogFiltrationForm(data)
    navigate(routes.Catalog)
  }

  const makesOptions = useVehicleMakeMenuOptions(t('pages.catalog.selectMake'))
  const modelsOptions = useVehicleModelMenuOptionsByMake(make, t('pages.catalog.selectModel'))

  const getVehicleCountsByMakeAndModel = useQueryGetVehicleCountsByMakeAndModel(
    buildQueryParamFromFilterValue(form.watch('make')),
    buildQueryParamFromFilterValue(form.watch('model'))
  )

  const onMakeChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    form.resetField('model')
    setMake(e.target.value)
  }

  return (
    <div className={styles.topMainContainer}>
      <PageSection maxWidth="md" className={styles.topMainSection}>
        <div className={styles.content}>
          <GoogleRating value={4.9} className={styles.googleRating} />
          <img src={SvgLogo} className={styles.cover} alt="logo" />
          <h1 className={styles.title}>{t('pages.main.sections.top.title')}</h1>

          <form
            onSubmit={form.handleSubmit(
              //navigate to the certain page where the user will see the selected cars(in props will add selected cars)
              navigateToCatalogHandler
            )}
          >
            <FlexContainer justifyContentSpaceBetween>
              <FormTextField
                control={form.control}
                name="make"
                select
                //label={t('pages.main.sections.top.form.brand')}
                className={formStyles.inputWhiteBg}
                onChange={onMakeChange}
                fullWidth
              >
                {renderMenuItems(makesOptions.data ?? [])}
              </FormTextField>
              <FormTextField
                control={form.control}
                name="model"
                select
                //label={t('pages.main.sections.top.form.model')}
                className={formStyles.inputWhiteBg}
                disabled={!make || make === AllValueSchema.value}
                fullWidth
              >
                {renderMenuItems(modelsOptions.data ?? [])}
              </FormTextField>
              <Button type="submit" variant="contained" size="small" endIcon={<ArrowForwardIcon />} fullWidth>
                {t('pages.main.sections.top.form.button', { count: getVehicleCountsByMakeAndModel.data ?? 0 })}
              </Button>
            </FlexContainer>
          </form>
        </div>
      </PageSection>
      <img src={ImgMainBackground} alt="background" className={styles.backgroundImage} />
    </div>
  )
}
